<template>
  <a-tag
    class="mb-1"
    color="blue"
    :spinning="loading"
    v-if="!loading"
    :loading="true"
    :closable="removeable"
    @close="onRemove"
  >
    <span class="text-sm">{{ displayText }}</span>
  </a-tag>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { DEPARTMENT_ACTIONS } from '@/store/actions'
import { IDepartment } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onRemove', 'update:onSelect'],
})
export default class DepartmentById extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop({ default: false })
  removeable!: boolean

  get loading() {
    return this.$store.state.departments.loading
  }

  get departments(): IDepartment[] {
    return this.$store.getters.departments
  }

  get displayText() {
    return this.departments.find((department) => department.id === this.id)?.name
  }

  @Emit('update:onRemove')
  onRemove() {
    return this.id
  }

  @Emit('update:onSelect')
  onSelect() {
    return this.id
  }

  async created() {
    if (!this.departments.length) {
      await this.$store.dispatch(DEPARTMENT_ACTIONS.LOAD_LIST)
    }
  }
}
</script>
