<template>
  <a-spin :spinning="loading" v-if="isOwner">
    <div class="pt-5 px-6">
      <div class="flex items-center flex-wrap">
        <h1 class="text-2xl flex-grow">
          <UserSwitchOutlined />
          Staffs management
        </h1>
        <div>
          <a-button type="primary" size="large" @click.prevent="onAdd">
            <PlusOutlined />
            Add new staff
          </a-button>
        </div>
      </div>
      <p class="text-gray-400 text-base">Manage company staffs</p>
    </div>

    <div class="bg-gray-200 px-6 py-2 flex items-center">
      <div class="flex-grow text-gray-400">Staffs</div>
      <div>
        <a-input-search v-model:value="searchQuery" placeholder="search staff" />
      </div>
    </div>

    <div class="px-4">
      <a-table :columns="columns" :data-source="staffsFiltered" rowKey="id">
        <template #avatar="{ text: avatar }">
          <a-avatar :size="60" shape="square" v-if="avatar" :src="avatar" />
          <a-avatar :size="60" style="background-color: #1890ff" shape="square" v-else>U</a-avatar>
        </template>

        <template #birthDate="{ text: birthDate }">
          <a-tag color="orange">
            <span class="text-sm">{{ formatDate(birthDate) }}</span>
          </a-tag>
        </template>

        <template #email="{ text: email }">
          <a class="text-sm" :href="`mailto:${email}`">{{ email }}</a>
        </template>

        <template #departmentIds="{ text: departmentIds }">
          <DepartmentById v-for="departmentId in departmentIds" :key="departmentId" :id="departmentId" />
        </template>

        <template #updatedAt="{ text: updatedAt }">
          <span class="text-gray-400">
            <ClockCircleOutlined />
            {{ formatDateTime(updatedAt) }}
          </span>
        </template>

        <template #action="{ text: id }">
          <a-space>
            <a-button default shape="circle" @click="onEdit(id)">
              <template #icon><FormOutlined /></template>
            </a-button>
            <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="onDelete(id)">
              <a-button danger shape="circle">
                <template #icon><DeleteOutlined /></template>
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-spin>

  <NotFound v-else />

  <StaffFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :id="selectedStaffId"
    @update:closeModal="modalVisible = false"
  />
</template>

<script lang="ts">
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IStaff, IUser } from '@/utils/types'
import {
  UserSwitchOutlined,
  PlusOutlined,
  FormOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons-vue'
import FireStaff from '@/services/staffs'
import StaffFormModal from '@/components/staff/StaffFormModal.vue'
import DepartmentById from '@/components/department/DepartmentById.vue'
import NotFound from '@/components/common/NotFound.vue'

import { formatDate, formatDateTime } from '@/utils/helpers'

@Options({
  components: {
    StaffFormModal,
    DepartmentById,
    NotFound,
    UserSwitchOutlined,
    PlusOutlined,
    FormOutlined,
    DeleteOutlined,
    ClockCircleOutlined,
  },
  directives: { maska },
})
export default class StaffList extends Vue {
  loading = false
  modalVisible = false
  selectedStaffId = ''

  searchQuery = ''

  staffs: IStaff[] = []
  staffsFiltered: IStaff[] = []

  columns = [
    {
      title: 'Photo',
      dataIndex: 'avatar',
      key: 'avatar',
      slots: { customRender: 'avatar' },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a: IStaff, b: IStaff) => (a.firstName?.length || 0) - (b.firstName?.length || 0),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a: IStaff, b: IStaff) => (a.lastName?.length || 0) - (b.lastName?.length || 0),
    },
    {
      title: 'Departments',
      dataIndex: 'departmentIds',
      key: 'departmentIds',
      sorter: (a: IStaff, b: IStaff) => (a.departmentIds?.length || 0) - (b.departmentIds?.length || 0),
      slots: { customRender: 'departmentIds' },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      slots: { customRender: 'email' },
    },
    {
      title: 'Birth Day',
      dataIndex: 'birthDate',
      key: 'birthDate',
      slots: { customRender: 'birthDate' },
      sorter: (a: IStaff, b: IStaff) => ((a?.birthDate as number) || 0) - ((b?.birthDate as number) || 0),
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  formatDate = formatDate
  formatDateTime = formatDateTime

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  @Watch('staffs', { deep: true })
  staffsChanged() {
    // Get latest update items by reverse
    this.staffsFiltered = reverse(uniqBy(reverse([...this.staffs]), 'id'))
  }

  onAdd() {
    this.selectedStaffId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedStaffId = id
    this.modalVisible = true
  }

  async onDelete(id: string) {
    await FireStaff.delete(id)
    message.success('Deleted successfully')
  }

  async getStaffs() {
    FireStaff.on((staff: IStaff) => {
      if (staff.fireChangeType === 'removed') {
        this.staffs = this.staffs.filter((item) => item.id !== staff.id)
      } else {
        this.staffs.push(staff)
      }
    })
  }

  beforeDestroy() {
    FireStaff.off()
    this.staffs = []
  }

  created() {
    this.getStaffs()
  }
}
</script>
