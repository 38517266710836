<template>
  <a-modal class="ant-modal--xl" :visible="modalVisible" @cancel="onCancel" :title="modalTitle" :loading="loading">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-spin :spinning="uploading">
        <div class="flex gap-4">
          <a-avatar shape="square" :size="100" v-if="form.avatar" :src="form.avatar" />
          <a-form-item label="Staff image" class="flex-grow">
            <a-upload
              block
              v-model:file-list="fileList"
              name="file"
              accept=".png,.jpeg,.jpg,.gif"
              :showUploadList="false"
              :multiple="false"
              :before-upload="beforeUpload"
            >
              <a-button type="primary" ghost>
                <UploadOutlined />
                Select image
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
      </a-spin>

      <a-divider />

      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="First Name" name="firstName">
            <a-input size="large" v-model:value="form.firstName" autocomplete="firstName" placeholder="first name" />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Last Name" name="lastName">
            <a-input size="large" v-model:value="form.lastName" autocomplete="lastName" placeholder="last name" />
          </a-form-item>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="Main Email" name="email">
            <a-input size="large" v-model:value="form.email" autocomplete="email" placeholder="main email" />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Second Email" name="secondEmail">
            <a-input
              size="large"
              v-model:value="form.secondEmail"
              autocomplete="secondEmail"
              placeholder="second email"
            />
          </a-form-item>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="Department" name="departmentId">
            <DepartmentSelectBox
              :selectedIds="form.departmentIds"
              @update:onSelect="(ids) => (form.departmentIds = ids)"
            />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Join date" name="joinDate">
            <a-date-picker size="large" v-model:value="form.joinDate" placeholder="YYYY/MM/DD" />
          </a-form-item>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="Phone Number" name="phone">
            <a-input size="large" v-model:value="form.phone" autocomplete="phone" placeholder="phone number" />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Birthday" name="birthDate">
            <a-date-picker size="large" v-model:value="form.birthDate" placeholder="YYYY/MM/DD" />
          </a-form-item>
        </div>
      </div>

      <a-form-item label="Address" name="address">
        <a-input size="large" v-model:value="form.address" autocomplete="address" placeholder="address info" />
      </a-form-item>

      <a-form-item label="Hometown" name="homeTown">
        <a-input size="large" v-model:value="form.homeTown" autocomplete="address" placeholder="hometown" />
      </a-form-item>

      <a-form-item label="Note" name="note" class="mt-4">
        <a-textarea v-model:value="form.note" autocomplete="note" placeholder="note here" :rows="4" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" type="default" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IFileItem, IStaff, IUser } from '@/utils/types'
import { UploadOutlined } from '@ant-design/icons-vue'
import { storageRef } from '@/utils/firebase'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import ImageUploadMixin from '@/components/mixins/ImageUploadMixin.vue'
import DepartmentSelectBox from '@/components/department/DepartmentSelectBox.vue'
import FireStaff from '@/services/staffs'
import moment from 'moment'

@Options({
  components: { DepartmentSelectBox, UploadOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardItemFormModal extends mixins(ImageUploadMixin) {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  fileList: IFileItem[] = []
  uploading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  emailRule = {
    required: false,
    type: 'email',
    message: 'Please input a valid email',
    trigger: 'blur',
  }

  form: IStaff = {
    departmentIds: [],
  }

  rules = {
    firstName: [this.requiredRule],
    lastName: [this.requiredRule],
    email: [{ ...this.emailRule, ...this.requiredRule }],
    departmentIds: [this.requiredRule],
    secondEmail: [this.emailRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new staff' : 'Edit staff information'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  @Watch('fileList')
  fileListChanged() {
    if (!this.fileList.length) {
      return
    }

    const file = this.fileList[0].originFileObj as Blob
    this.uploading = true
    storageRef
      .child(`${this.id || uuidv4()}.jpeg`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((publicUrl) => {
          this.form.avatar = publicUrl
        })
      })
      .finally(() => {
        this.uploading = false
      })
  }

  fixDataToSave() {
    const dataToSave = cloneDeep(this.form)
    if (dataToSave.birthDate) {
      dataToSave.birthDate = (dataToSave.birthDate as moment.Moment).unix()
    }

    if (dataToSave.joinDate) {
      dataToSave.joinDate = (dataToSave.joinDate as moment.Moment).unix()
    }
    return dataToSave
  }

  rawToFormData(rawData: IStaff) {
    if (rawData.birthDate) {
      rawData.birthDate = moment.unix(rawData.birthDate as number)
    }

    if (rawData.joinDate) {
      rawData.joinDate = moment.unix(rawData.joinDate as number)
    }

    return rawData
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const dataToSave = this.fixDataToSave()
    this.loading = true
    if (!this.id && this.userInfo.id) {
      await FireStaff.add(dataToSave)
    } else {
      await FireStaff.update(dataToSave)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getStaff() {
    return await FireStaff.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const staff = await this.getStaff()
      const dataForm = this.rawToFormData(staff)
      this.form = { ...this.form, ...dataForm }
    }
  }
}
</script>
