
import { Options, mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IFileItem, IStaff, IUser } from '@/utils/types'
import { UploadOutlined } from '@ant-design/icons-vue'
import { storageRef } from '@/utils/firebase'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import ImageUploadMixin from '@/components/mixins/ImageUploadMixin.vue'
import DepartmentSelectBox from '@/components/department/DepartmentSelectBox.vue'
import FireStaff from '@/services/staffs'
import moment from 'moment'

@Options({
  components: { DepartmentSelectBox, UploadOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardItemFormModal extends mixins(ImageUploadMixin) {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  fileList: IFileItem[] = []
  uploading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  emailRule = {
    required: false,
    type: 'email',
    message: 'Please input a valid email',
    trigger: 'blur',
  }

  form: IStaff = {
    departmentIds: [],
  }

  rules = {
    firstName: [this.requiredRule],
    lastName: [this.requiredRule],
    email: [{ ...this.emailRule, ...this.requiredRule }],
    departmentIds: [this.requiredRule],
    secondEmail: [this.emailRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new staff' : 'Edit staff information'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  @Watch('fileList')
  fileListChanged() {
    if (!this.fileList.length) {
      return
    }

    const file = this.fileList[0].originFileObj as Blob
    this.uploading = true
    storageRef
      .child(`${this.id || uuidv4()}.jpeg`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((publicUrl) => {
          this.form.avatar = publicUrl
        })
      })
      .finally(() => {
        this.uploading = false
      })
  }

  fixDataToSave() {
    const dataToSave = cloneDeep(this.form)
    if (dataToSave.birthDate) {
      dataToSave.birthDate = (dataToSave.birthDate as moment.Moment).unix()
    }

    if (dataToSave.joinDate) {
      dataToSave.joinDate = (dataToSave.joinDate as moment.Moment).unix()
    }
    return dataToSave
  }

  rawToFormData(rawData: IStaff) {
    if (rawData.birthDate) {
      rawData.birthDate = moment.unix(rawData.birthDate as number)
    }

    if (rawData.joinDate) {
      rawData.joinDate = moment.unix(rawData.joinDate as number)
    }

    return rawData
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const dataToSave = this.fixDataToSave()
    this.loading = true
    if (!this.id && this.userInfo.id) {
      await FireStaff.add(dataToSave)
    } else {
      await FireStaff.update(dataToSave)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getStaff() {
    return await FireStaff.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const staff = await this.getStaff()
      const dataForm = this.rawToFormData(staff)
      this.form = { ...this.form, ...dataForm }
    }
  }
}
