
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { IFileItem } from '@/utils/types'

@Options({
  components: { UserOutlined, LockOutlined },
  directives: { maska },
})
export default class ImageUploadMixin extends Vue {
  beforeUpload = (file: IFileItem) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif'

    if (!isJpgOrPng) {
      message.error('You can only upload JPG, PNG, GIF file!')
    }

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }

    return false
  }
}
