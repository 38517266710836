<template>
  <a-spin :spinning="loading">
    <a-select mode="multiple" v-model:value="selectedIdsLocal" @change="onSelect" size="large">
      <a-select-option v-for="department of departments" :key="department.id" :value="department.id">
        {{ department.name }}
      </a-select-option>
    </a-select>
  </a-spin>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDepartment } from '@/utils/types'
import FireDepartment from '@/services/departments'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class DepartmentSelectBox extends Vue {
  @Prop()
  selectedIds!: string[]

  loading = false
  selectedIdsLocal: string[] = []
  searchQuery = ''

  departments: IDepartment[] = []

  @Emit('update:onSelect')
  onSelect() {
    return this.selectedIdLocal
  }

  async getDepartments() {
    this.departments = await FireDepartment.list()
  }

  beforeDestroy() {
    this.departments = []
  }

  async created() {
    await this.getDepartments()
    this.selectedIdsLocal = this.selectedIds
  }
}
</script>
